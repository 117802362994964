<template>
  <div class="vh-100 d-flex">
    <b-container class="d-flex justify-content-center align-self-center">
      <b-row>
        <b-col cols="12">
          <b-card body-class="d-flex flex-column align-items-center p-3">
              <b-icon :icon="success == 'true' ? 'check-circle' : 'x-circle'" :variant="success == 'true' ? 'success' : 'danger' " class="mb-2" style="width: 100px; height: 100px;"></b-icon>
              <h1>{{success == 'true' ? "Geslaagd" : "Fout"}}</h1>
              <p class="h5" v-html="messageTranslation"></p>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

export default {
    components: {
    },
    metaInfo: {
        title: "Melding"
    },
    data() {
        return {
            success: this.$route.query.success,
            title: null,
            message: this.$route.query.message,
            messageTranslation: null,
            type: this.$route.query.type,
        }
    },
    mounted() {
      this.translateMessage()
    },
    methods: {
      translateMessage() {
        if(this.type == "emailVerification") {
          if(this.message == "Your email was verified. You can continue using the application.") {
            this.messageTranslation = "Je e-mail is geverifieerd."
          } else if(this.message == "This URL can be used only once") {
            this.messageTranslation = "Deze URL is al een keer gebruikt."
          } else if(this.message == "Access expired.") {
            this.messageTranslation = "Toegang verlopen."
          } else if(this.message == "User account does not exist or verification code is invalid.") {
            this.messageTranslation = "Account bestaat niet of de verificatie code is onjuist."
          } else if(this.message == "This account is already verified.") {
            this.success = "true"
            this.messageTranslation = "Dit account is al geverifieerd."
          }
        } else if(this.type == "passwordChange") {
          if(this.message == "You can now login to the application with the new password.") {
            this.messageTranslation = "Je kunt nu inloggen met je nieuwe wachtwoord."
          } else if(this.message == "This URL can be used only once") {
            this.messageTranslation = "Deze URL is al een keer gebruikt."
          } else if(this.message == "Access expired.") {
            this.messageTranslation = "Toegang verlopen."
          } else if(this.message == "The operation cannot be completed. Please try again.") {
            this.messageTranslation = "Er ging iets fout. Probeer opnieuw."
          }
        } else {
          this.messageTranslation = "Er ging iets fout. Contacteer info@familiebank.nl"
        }
      }
    }
};
</script>

<style scoped>

</style>